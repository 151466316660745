@import '../../sass/vars';

.dock {
  position: absolute;
  bottom: 10px;
  @include flex-box(space-between, center);
  gap: 10px;
  border-radius: 10px;
  width: fit-content;
  height: 75px;
  padding: 10px;

  /* From https://css.glass */
  background: rgba(65, 65, 65, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(65, 65, 65, 1);

  div {
    width: 1px;
    background-color: gray;
    height: 100%;
    opacity: 0.8;
  }

  .dock-item {
    height: 50px;

    img {
      width: 50px;
      // width: inherit;
      height: inherit;
      border-radius: 12px;

      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
