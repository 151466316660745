$black: #000000;
$white: #ffffff;

$text: $white;
$text-hover: #8798ad;

$main-font: 'Nunito', sans-serif !important;

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@mixin link-hover($color: $text-hover) {
  text-decoration: none;

  &:hover {
    color: $color;
  }
}

@mixin flex-box($dir: row, $just: space-evenly, $align: center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
}
