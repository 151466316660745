@import './sass/vars';

// reset browser default
*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

body {
  font-family: $main-font;
}
