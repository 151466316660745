@import '../../sass/vars';

.desktop {
  width: 100%;
  height: calc(100vh - var(--vh-offset, 0px)); // vh-check
  background-image: url('../../../public/sequoia-dark-min.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  @include flex-box();
  z-index: 10;
  opacity: 0;
  position: fixed;
  top: 0;

  animation: fade-in 0.5s ease 1.5s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
