@import '../../sass/vars';

.loading-screen {
  width: 100%;
  height: calc(100vh - var(--vh-offset, 0px)); // vh-check
  background-color: #000;
  @include flex-box(column, center);
  gap: 25px;
}

.loading {
  width: 250px;
  height: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.loading:after {
  content: '';
  display: block;
  width: 0%;
  height: 4px;
  background: #fff;
  animation: load 1s 0.5s linear;
}

@-moz-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
@keyframes load {
  0% {
    width: 0;
  }
  10% {
    width: 5%;
  }
  20% {
    width: 15%;
  }
  30% {
    width: 25%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 44%;
  }
  60% {
    width: 50%;
  }
  70% {
    width: 72%;
  }
  80% {
    width: 84%;
  }
  90% {
    width: 92%;
  }
  100% {
    width: 100%;
  }
}
