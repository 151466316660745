@import '../../sass/vars';

// code editor colors
$nav-bar: #333333;
$file-name: #818488;
$window: #252526;
$red-btn: #a60d03;
$yellow-btn: #a67905;
$green-btn: #558121;
$code: #48a01c;

.CodeEditor {
  width: 100%;
  max-width: 1150px;
  min-height: 350px;
  @include flex-box();

  .window {
    width: 60%;
    border-radius: 10px;
    box-shadow: 2px 2px 10px $black;
    font-family: monospace;
    font-weight: 100;
  }

  /* nav-bar code */
  .nav-bar {
    position: relative;
    width: 100%;
    height: 4vh;
    background-color: $nav-bar;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include flex-box();
  }

  .nav-bar p {
    margin: 0;
    color: $file-name;
    font-weight: 400;
    font-size: medium;
  }

  /* nav-bar icons */
  .nav-bar-icons {
    position: absolute;
    left: 10px;
    width: 10%;
    @include flex-box(space-evenly);
  }

  .nav-bar-icons div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    @include flex-box();
    font-size: x-small;
    color: $nav-bar;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-bar-icons div:nth-child(1) {
    background-color: $red-btn;
  }

  .nav-bar-icons div:nth-child(2) {
    background-color: $yellow-btn;
  }

  .nav-bar-icons div:nth-child(3) {
    background-color: $green-btn;
  }

  /* code window */
  .text-area {
    min-height: 20vh;
    height: auto;
    background-color: $window;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 16px;
    overflow: auto;
    display: flex;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .code {
      width: 100%;
      font-weight: bolder;
      // this reads tabs \t from js str
      white-space: pre;
      color: $code;
    }

    .cursor {
      width: 0.7rem;
      height: 1rem;
      margin-left: 2px;
      background: $white;
      opacity: 0;
      display: inline-flex;
      border-radius: 1px;
      animation: blinking-cursor ease 1.2s infinite;
    }

    .line-number {
      color: $file-name;
      padding-right: 10px;
      text-align: right;
    }

    .code,
    .cursor,
    .line-number {
      font-size: large;
      line-height: 1.5rem;
    }
  }

  @keyframes blinking-cursor {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  /* phones landscape */
  @media (min-width: 0) and (max-height: 500px) {
    .window {
      width: 60% !important;
    }

    .nav-bar {
      height: 6vh;
    }

    .nav-bar-icons {
      width: 12%;
    }
  }

  /* phones */
  @media (min-width: 0px) and (max-width: 576px) {
    .window {
      width: 90%;
    }

    .nav-bar-icons {
      width: 15%;
    }

    .nav-bar-icons div {
      width: 10px;
      height: 10px;
    }

    .code,
    .cursor,
    .line-number {
      font-size: x-small;
      line-height: 1rem;
    }
  }

  /* tablet */
  @media (min-width: 577px) and (max-width: 1025px) {
    .window {
      width: 70%;
    }
  }

  // lg desktop portrait
  @media (orientation: portrait) and (min-height: 1200px) {
    .nav-bar {
      height: 2vh;
    }
  }

  // lg desktop landscape
  @media (orientation: landscape) and (min-height: 1200px) {
    .nav-bar {
      height: 2.5vh;
    }
  }
}
